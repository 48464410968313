<div class="menu">
    <div class="brand">
        Softygon Portal
        <div class="company">{{ companies.currentCustomer.name }}</div>
    </div>
    <app-menu></app-menu>
</div>
<div class="content">
    <div class="toolbar">
        <a *ngIf="backUrl" [routerLink]="[backUrl]" class="back"><i class="icon-chevron-left"></i></a>
        <div class="spacer"></div>
        <sg-lang></sg-lang>
        <div class="sg-link secondary companies" [routerLink]="['/companies']"> {{ 'companies' | translate }} </div>
        <app-session></app-session>
    </div>
    <div class="router-container">
        <router-outlet></router-outlet>
    </div>
</div>