import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-client-opts',
  templateUrl: './client-opts.component.html',
  styleUrls: ['./client-opts.component.scss']
})
export class ClientOptsComponent {

  constructor(
    public dialogRef: MatDialogRef<ClientOptsComponent>,
  ) { }

  select(lang: string): void {
    this.dialogRef.close(lang);
  }
}
