<div class="page-container">
    <div class="header">
        <div class="title-row">
            <div class="title"><ng-content select="[listTitle]"></ng-content></div>
            <ng-content select="[listButtons]"></ng-content>
        </div>
        <sg-paginator *ngIf="paginator" [paginator]="paginator"></sg-paginator>
        <ng-content select="app-tabs"></ng-content>
        <mat-progress-bar *ngIf="!dataSource || dataSource.loading" mode="indeterminate"></mat-progress-bar>
    </div>
    <app-table *ngIf="dataSource" [dataSource]="dataSource"></app-table>
</div>