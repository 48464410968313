import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { LoginDialogComponent } from 'src/app/components/login-dialog/login-dialog.component';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SgAdhocLoginService {

  private openedLogin: Observable<boolean>;

  constructor(private dialog: MatDialog) { }

  public adHocLogin(): Observable<boolean> {
    if(!this.openedLogin) {
      this.openedLogin = this.dialog.open(LoginDialogComponent, {width: '300px'})
        .beforeClosed()
        .pipe(tap(
          () => {this.openedLogin = null;},
          () => {this.openedLogin = null;},
          () => {this.openedLogin = null;},
        ));
    }
    return this.openedLogin;
  } 
}
