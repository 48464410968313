import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { SessionService } from '../session/session.service';
import { Observable } from 'rxjs';
import { SgAdhocLoginService } from '../adhoc-login/adhoc-login.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivateChild {

  constructor(private session: SessionService, private adHocLogin: SgAdhocLoginService) {}

  canActivateChild(): boolean | Observable<boolean> {
    if(this.session.data?.token) {
      return true;
    } else {
      return this.adHocLogin.adHocLogin();
    }
  }

}
