import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SgPaginator } from '../paginator/paginator.component';
import { SgDataSource } from '../table/table.model';

@Component({
  selector: 'app-list-layout',
  templateUrl: './list-layout.component.html',
  styleUrls: ['./list-layout.component.scss']
})
export class ListLayoutComponent implements OnInit {

  @Input() dataSource: SgDataSource<unknown>;
  @Input() paginator: SgPaginator;

  constructor() { }

  ngOnInit(): void {
  }

}
