import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SgBaseServicesService } from './base-services.service';
import { SessionService } from '../../session/session.service';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StateService } from '../../state/state.service';

export class SgRemote {
    public http: HttpClient;
    public session: SessionService;
    protected state: StateService;

    constructor(services: SgBaseServicesService) {
        this.http = services.http;
        this.state = services.state;
    }

    public getCompanyUri(trail: string, companyID: string | number = this.state.params.companyID) {
        return this.getURI('customer/' + companyID + '/' + trail)
    }

    public getURI(trail: string) {
        return environment.uri + trail;
    }

    public copy<T>(target: Subject<T>): MonoTypeOperatorFunction<T> {
        return tap(
            s => target.next(s),
            e => target.error(e),
        );
    }

    public copyToBool<T>(target: Subject<boolean>): MonoTypeOperatorFunction<T> {
        return tap(
            s => target.next(true),
            e => target.next(false),
        );
    }
}