import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SgLoginFormComponent } from './login-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseComponentsModule } from '../base-components.module';



@NgModule({
  declarations: [SgLoginFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BaseComponentsModule
  ],
  exports: [
    SgLoginFormComponent
  ]
})
export class SgLoginFormModule { }
