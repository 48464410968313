import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LoggedInGuard } from './services/guards/logged-in.guard';
import { CompanySelectedLayoutComponent } from './components/layouts/company-selected-layout/company-selected-layout.component';
import { CompanySelectedGuardGuard } from './services/guards/company-selected-guard.guard';


const routes: Routes = [
  {
    path: 'companies',
    canActivateChild: [LoggedInGuard],
    children: [
      {
        path: '',
        loadChildren: () => import("./pages/customers/customers.module").then(m => m.CustomersModule)
      },
      {
        path: ':companyID',
        component: CompanySelectedLayoutComponent,
        canActivate: [CompanySelectedGuardGuard],
        children: [
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full'
          },
          {
            path: 'dashboard',
            loadChildren: () => import("./pages/dashboard/dashboard.module").then(m => m.DashboardModule)
          },
          {
            path: 'dashboard/after-payment/:paymentResult',
            loadChildren: () => import("./pages/dashboard/dashboard.module").then(m => m.DashboardModule)
          },
          {
            path: 'docs',
            loadChildren: () => import("./pages/docs/docs.module").then(m => m.DocsModule)
          },
          {
            path: 'bills',
            loadChildren: () => import("./pages/bills/bills.module").then(m => m.BillsModule)
          },
          {
            path: 'pipelines',
            loadChildren: () => import("./pages/pipelines/pipelines.module").then(m => m.PipelinesModule)
          },
          {
            path: 'troubleshoot',
            loadChildren: () => import("./pages/troubleshoot/troubleshoot.module").then(m => m.TroubleshootModule)
          },
          {
            path: 'users',
            loadChildren: () => import("./pages/users/users.module").then(m => m.UsersModule)
          },
          {
            path: 'settings',
            loadChildren: () => import("./pages/settings/settings.module").then(m => m.SettingsModule)
          },
          {
            path: 'variables',
            loadChildren: () => import("./pages/variables/variables.module").then(m => m.VariablesModule)
          }
        ]
      },
    ]
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'verification',
    loadChildren: () => import('./pages/verification/verification.module').then(m => m.VerificationModule)
  },
  {
    path: 'password-request',
    loadChildren: () => import('./pages/password-request/password-request.module').then(m => m.PasswordRequestModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then(m => m.PasswordResetModule)
  },
  {
    path: 'login',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
