<table>
    <thead>
        <tr>
            <th *ngIf="dataSource.checking" class="checking">
                <mat-checkbox [formControl]="dataSource.globalToggler"></mat-checkbox>
            </th>
            <th *ngFor="let col of dataSource.cols">
                {{ col.header | translate }}
            </th>
            <th *ngIf="dataSource.onRowDelete">
                -
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of dataSource.rows">
            <td *ngIf="dataSource.checking" class="checking">
                <mat-checkbox [formControl]="dataSource.togglers.get(row)"></mat-checkbox>
            </td>
            <td *ngFor="let col of dataSource.cols" [ngSwitch]="col.type">
                <a *ngIf="row.__link__; else simple" [routerLink]="row.__link__">
                    <ng-container *ngTemplateOutlet="cellContent"></ng-container>
                </a>
                <ng-template #simple>
                    <div [ngClass]="{'has-click': dataSource.onRowClick}" (click)="dataSource.onRowClick && dataSource.onRowClick(row)">
                        <ng-container *ngTemplateOutlet="cellContent"></ng-container>
                    </div>
                </ng-template>
                <ng-template #cellContent>
                    <ng-container *ngSwitchCase="'accessor'">
                        <span class="accessor">
                            {{ row[col.id].model }}
                        </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'translate'">
                        {{ (col.translatePrefix + row[col.id]) | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'action'">
                        <div class="small sg-btn" (click)="col.callback(row)">{{ col.actionLabel | translate }}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'status'">
                        <span class="status" [ngClass]="row[col.id]">
                            {{ 'status.' + (row[col.id] || 'UNKNOWN') | translate }}
                        </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'datetime'">
                        {{ row[col.id] | date:'medium':'CET':locale }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'bool'">
                        <ng-container [ngSwitch]="row[col.id]">
                            <i class="icon-checkmark2" *ngSwitchCase="true"></i>
                            <i class="icon-minus1" *ngSwitchCase="false"></i>
                            <i class="icon-question-circle-o null-bool" *ngSwitchDefault></i>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{ row[col.id] }}
                    </ng-container>
                </ng-template>
            </td>
            <td *ngIf="dataSource.onRowDelete">
                <div (click)="dataSource.onRowDelete(row)">Delete</div>
            </td>
        </tr>
    </tbody>
</table>
