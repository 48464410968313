import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pseudo-logo',
  templateUrl: './pseudo-logo.component.html',
  styleUrls: ['./pseudo-logo.component.scss']
})
export class PseudoLogoComponent implements OnInit {

  @Input() addText: string;
  @Input() onlyText: string;

  constructor() { }

  ngOnInit(): void {
  }

}
