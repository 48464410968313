import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import localeSk from '@angular/common/locales/sk';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeSk);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(primengConfig: PrimeNGConfig, translate: TranslateService) {
    primengConfig.ripple = true;

    let lang: string;

    try {
      lang = localStorage.getItem('lang');
    } catch(e) {
      console.warn(e);
    }

    translate.use(lang ? lang : 'en');
  }
}
