import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { SessionService } from '../session/session.service';
import { catchError, take } from 'rxjs/operators';
import { SgAdhocLoginService } from '../adhoc-login/adhoc-login.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private session: SessionService, private adHocLogin: SgAdhocLoginService) {

  }

  private getHandled(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes(environment.uri)) {
      let headers = req.headers
        .set('Content-Type', 'application/json')
        .set('Authorization', this.session.data ? this.session.data.token : '');
  
      return next.handle(req.clone({headers: headers}));
    }

    return next.handle(req);
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const doRequest = () => {
      return this.getHandled(req, next)
        .pipe(
          catchError((error: HttpErrorResponse) => {     
            if (error && error.status === 401 && !req.url.includes('login')) {
              this.session.invalidateToken();
              return doLogin();
            } else {
              return throwError(error);
            }
          })
        )
    }

    const doLogin = () => {
      const finalResponse: ReplaySubject<HttpEvent<unknown>> = new ReplaySubject();

      this.adHocLogin.adHocLogin()
        .pipe(take(1))
        .subscribe(result => {
          if(result) {
            doRequest()
              .subscribe(
                s => finalResponse.next(s),
                e => finalResponse.error(e),
                () => finalResponse.complete()
              );
          }
        });

      return finalResponse;
    }

    return doRequest();
  }
}
