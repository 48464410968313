import { Component, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SessionService } from 'src/app/services/session/session.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class SgLoginFormComponent {

  @Output() onSuccess: EventEmitter<void> = new EventEmitter();

  public emailInput = new UntypedFormControl('');
  public passwordInput = new UntypedFormControl('');
  public badPassword: boolean;
  public loading: boolean;

  constructor(private session: SessionService) { }

  submit(): void {
    this.loading = true;
    this.badPassword = false;
    this.session.login(this.emailInput.value, this.passwordInput.value)
      .pipe(take(1))
      .subscribe(
        () => {
          this.onSuccess.next();
          this.loading = false;
        },
        () => {
          this.badPassword = true;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

}
