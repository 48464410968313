<div class="container">
    <div class="spinner" *ngIf="loading; else uploadFile">

    </div>
    <ng-template #uploadFile>
        <h1>
            Upload {{ type }} file
        </h1>
        <label class="custom-upload">
            <div class="sg-btn base-width">Choose file</div>
            <input type="file" (change)="handleFileInput($event.target.files)">
        </label>
    </ng-template>
</div>