import { Injectable } from '@angular/core';
import { SgRemote } from '../core/remotes/remotes';
import { SgBaseServicesService } from '../core/remotes/base-services.service';
import { Subject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { copyToSubject } from 'src/app/utils/common';
import { map, take } from 'rxjs/operators';

export interface SgiCustomer {
  id: number,
  isactive: boolean,
  name: string,
}

@Injectable({
  providedIn: 'root'
})
export class SgCustomersService extends SgRemote {

  public currentCustomer: SgiCustomer;
  public fetchedCustomers: SgiCustomer[] = [];
  public currentRoles: string[] = [];
  public isAdmin: boolean;

  constructor(services: SgBaseServicesService) {
    super(services);
  }

  public fetchCustomers(): Observable<SgiCustomer[]> {
    const response: Subject<SgiCustomer[]> = new Subject();
    this.http.get<SgiCustomer[]>(this.getURI('customers'))
      .pipe(copyToSubject(response))
      .subscribe(result => {
        this.fetchedCustomers = result;
      });
    return response;
  }

  public createCompany(data: {name: string}): Observable<SgiCustomer> {
    return this.http
      .post<SgiCustomer>(this.getURI('customers/'), data)
      .pipe(take(1));
  }

  public activateCompany(id: number): Observable<boolean> {

    const permissionsDone: ReplaySubject<boolean> = new ReplaySubject();
    const fetchDone: ReplaySubject<boolean> = new ReplaySubject();

    this.http.get<string[]>(this.getCompanyUri('user-permissions/', id))
      .subscribe(
        res => {
          this.currentRoles = res;
          this.isAdmin = res.includes('admin');
          permissionsDone.next(true);
        },
        err => permissionsDone.next(false)
      )
  
    const found = this.fetchedCustomers.find(e => e.id === id);

    if(found) {
      this.currentCustomer = found;
      fetchDone.next(true);
    } else {
      this.fetchCustomers()
        .pipe(take(1))
        .subscribe(
          freshCustomers => {
            this.currentCustomer = freshCustomers.find(e => e.id === id);
            fetchDone.next(!!this.currentCustomer);
          },
          _ => {
            this.currentCustomer = null;
            fetchDone.next(false);
          }
        );
    }

    return combineLatest([permissionsDone, fetchDone]).pipe(map(res => res[0] && res[1]));
  }
}
