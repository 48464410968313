import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class SgPaginator {
  public page = 1;
  public hasNext: boolean;
  public page$: BehaviorSubject<number> = new BehaviorSubject(1);

  public next(): void {
    if (this.hasNext) {
      this.page++;
      this.page$.next(this.page);
    }
  }

  public prev(): void {
    if (this.page > 1) {
      this.page--;
      this.page$.next(this.page);
    }
  }
}

@Component({
  selector: 'sg-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() paginator: SgPaginator;
}
