import { tap } from "rxjs/operators"
import { MonoTypeOperatorFunction, Observable, Subject } from 'rxjs';
import { SgiColumn } from '../components/table/table.model';
import { ActivatedRouteSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

export function copyToSubject<T>(to: Subject<T>): MonoTypeOperatorFunction<T> {
    return tap(
        res => to.next(res),
        err => to.error(err),
        () => to.complete()
    )
}

export class SgColumns {
    public static basic(id: string, header: string = 'basic.' + id): SgiColumn {
        return {id, header};
    }

    public static translate(id: string, translatePrefix: string, header: string = 'basic.' + id): SgiColumn {
      return {id, header, translatePrefix, type: 'translate'};
    }

    public static action<T>(id: string, actionLabel: string, callback: (row: T) => void | unknown, header: string = 'basic.' + id): SgiColumn {
      return {
        id,
        header,
        callback,
        actionLabel,
        type: 'action'
      }
    }

    public static accessor(id: string, header: string = 'basic.' + id): SgiColumn {
        return {
            id,
            header,
            type: 'accessor'
        }
    }

    public static delete(id: string, callback: (id: unknown) => void | unknown): SgiColumn {
      return {
        id,
        header: 'delete',
        type: 'delete'
      }
    }

    public static status(id: string, header: string = 'basic.' + id): SgiColumn {
        return {
            id,
            header,
            type: 'status'
        }
    }

    public static bool(id: string, header: string = 'basic.' + id): SgiColumn {
        return {
            id,
            header,
            type: 'bool'
        }
    }

    public static datetime(id: string, header: string = 'basic.' + id): SgiColumn {
        return {
            id,
            header,
            type: 'datetime'
        }
    }

    public static multi(prefix: string, ids: (string | SgiColumn)[]): SgiColumn[] {
        return ids.map(e => typeof e === 'string' ? SgColumns.basic(e, prefix + e) : e);
    }
}

export function applyFilter<T>(filter: string, arr: T[], prop: string): T[] {
    const escape: RegExp = new RegExp(/[.*+?^${}()|[\]\\]/g);
    const regFilter = new RegExp('.*' + filter.toLocaleLowerCase().split('').map(s => s.replace(escape, '\\$&')).join('.{0,3}') + '.*');
    return arr.filter(f => regFilter.test((f[prop]).toLocaleLowerCase()));
}

export function buildURI(snapshot: ActivatedRouteSnapshot, trail: string): string {
    let result = environment.uri;

    // level 1
    if (snapshot.paramMap.get('companyID')) {
        result += 'customer/' + snapshot.paramMap.get('companyID') + '/';
    }

    // level 2
    if (snapshot.paramMap.get('pipelineID')) {
        result += 'pipelines/' + snapshot.paramMap.get('pipelineID') + '/';
    }

    if (snapshot.paramMap.get('variableID')) {
        result += 'variables/' + snapshot.paramMap.get('variableID') + '/';
    }

    // level 3
    if (snapshot.paramMap.get('datapairID')) {
      result += 'datapairs/' + snapshot.paramMap.get('datapairID') + '/';
    }

    if (snapshot.paramMap.get('runID')) {
        result += 'runs/' + snapshot.paramMap.get('runID') + '/';
    }

    // last level
    if (trail) {
        result += trail.replace(':id', snapshot.paramMap.get('id'));
    }

    return result;
}