<div class="intro">
    <img src="/assets/icon_black.png"/>
</div>
<div class="choose-title">
    <span *ngIf="onlyText; else classic" class="add-text">
        {{ onlyText }}
    </span>
    <ng-template #classic>
        <span *ngIf="addText" class="add-text">{{ addText }} </span>
        Softygon
    </ng-template>
</div>