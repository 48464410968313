import { Component, Input, OnChanges } from '@angular/core';
import { UrlSegment } from '@angular/router';

export interface TabDefinition {
  link: (string | UrlSegment)[];
  label: string;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnChanges {

  @Input() tabs: TabDefinition[] = [];
  public selected: TabDefinition;

  constructor() { }

  ngOnChanges(): void {
    this.selected = this.tabs.find(t => location.pathname.startsWith(t?.link.join('/')));
  }

}
