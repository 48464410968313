import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SgiRow } from 'src/app/components/table/table.model';
import { SgBaseServicesService } from 'src/app/services/core/remotes/base-services.service';
import { SgRemote } from 'src/app/services/core/remotes/remotes';

export interface CompanyInfo {
  is_subscribed: boolean | null,
  payment_model: string
}

export interface Bill extends SgiRow {
  period: string;
  total: number;
  totalVat: number;
}

export interface BillRow extends SgiRow {
  pipeline_name: string;
  price: number;
  price_vat: number;
  total_ms: number;
  total_requests: number;
  total_files: number; 
}

@Injectable({
  providedIn: 'root'
})
export class BillsService extends SgRemote {
  constructor(services: SgBaseServicesService, private router: Router) {
    super(services);
  }

  getTabs() {
    const prefix = this.state.getURL('/companies/:companyID/bills');
    return [
      {
        link: [prefix, 'invoices'],
        label: 'invoices'
      },
      {
        link: [prefix, 'usage'],
        label: 'usage'
      },
    ];
  }

  public navigateToPlans() {
    this.router.navigateByUrl(this.state.getURL('/companies/:companyID/bills/invoices/plans'));
  }

  public getBills(page: number): Observable<Bill[]> {
    return this.http.get<Bill[]>(
      this.state.getCompanyApiURL('bills'), {params: {page: '' + page}}
    );
  }

  public getBill(): Observable<BillRow[]> {
    return this.http.get<BillRow[]>(
      this.state.getCompanyApiURL('bills/:billID')
    );
  }

  public getInvoices() {
    return this.http.get<SgiRow[]>(this.state.getCompanyApiURL('billing/invoices'));
  }

  public getPlans() {
    return this.http.get<SgiRow[]>(this.state.getCompanyApiURL('billing/plans'));
  }

  public setupPlan(priceId: string) {
    return this.http.post<{url: string}>(this.state.getCompanyApiURL('billing/init'), {
      priceId,
      ruri: window.location.origin + this.state.getURL('/companies/:companyID/dashboard/after-payment')
    });
  }

  public getCustomerInfo() {
    return this.http.get<CompanyInfo>(this.state.getCompanyApiURL('').replace('customer', 'customers'));
  }
}
