import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sg-json-input',
  templateUrl: './json-input.component.html',
  styleUrls: ['./json-input.component.scss']
})
export class JsonInputComponent implements OnInit, OnDestroy {

  public invalid: boolean;
  public inactive: boolean;
  private controlAssigned = new Subject();
  private destroyed$ = new Subject();

  @Input()
  set control(control: UntypedFormControl) {
    if (!control) {
      return;
    }

    this._control = control;
    this.controlAssigned.next();
    let isAssigning: boolean;

    function jsonToString(data: unknown): string {
      return JSON.stringify(data ? data : {}, null, 2);
    }

    this.stringState.setValue(jsonToString(control.value), {emitEvent: false});

    control.valueChanges
      .pipe(
        takeUntil(this.controlAssigned),
        takeUntil(this.destroyed$)
      )
      .subscribe(changed => {
        if (!isAssigning) {
          this.stringState.setValue(jsonToString(changed), {emitEvent: false});
        }
      });

    this.stringState.valueChanges
      .pipe(
        takeUntil(this.controlAssigned),
        takeUntil(this.destroyed$),
        debounceTime(1000)
      )
      .subscribe(() => {
        this.inactive = true;
      });

    this.stringState.valueChanges
      .pipe(
        takeUntil(this.controlAssigned),
        takeUntil(this.destroyed$)
      )
      .subscribe(changed => {
        this.inactive = false;
        try {
          isAssigning = true;
          control.setValue(JSON.parse(changed));
          isAssigning = false;
          this.invalid = false;
        } catch {
          this.invalid = true;
        }
      });
  }
  get control(): UntypedFormControl {
    return this._control;
  }
  private _control: UntypedFormControl = new UntypedFormControl({});

  public stringState: UntypedFormControl = new UntypedFormControl('{}');

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
