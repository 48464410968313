import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

const SESSION_DATA_LS_KEY: string = 'sgSessionData';

export type SgRole = 'user' | 'admin' | 'superadmin';

export interface SessionData {
  user: string;
  token: string;
  roles: SgRole[];
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public data: SessionData = null;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    const fromLS: SessionData = JSON.parse(localStorage.getItem(SESSION_DATA_LS_KEY));

    if(fromLS?.token && fromLS.user) {
      this.applySession(fromLS);
    }
  }

  private applySession(data: SessionData): void {
    this.data = data;
    localStorage.setItem(SESSION_DATA_LS_KEY, JSON.stringify(this.data));
  }

  private parseToken(token: string): {email: string, roles: SgRole[]} {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  public loginWithToken(token: string): void {
    const parsed = this.parseToken(token);

    this.applySession({
      user: parsed.email,
      token: token,
      roles: parsed.roles
    });
  }

  public invalidateToken(): void {
    this.applySession(null);
  }

  public login(email: string, password: string): Observable<void> {
    const response: Subject<void> = new Subject();

    this.http.post<{token: string}>(environment.uri + 'login', {
      email: email,
      password: password
    })
      .pipe(take(1))
      .subscribe(
        res => {
          const parsed = this.parseToken(res.token);

          this.applySession({
            token: res.token,
            user: parsed.email,
            roles: parsed.roles
          });
          response.next();
        },
        err => {
          response.error(err);
        }
      );

    return response;
  }

  public register(email: string, password: string): Observable<unknown> {
    return this.http.post(environment.uri + 'registration', {
      email: email,
      password: password
    })
      .pipe(take(1));
  }

  public logout(): void {
    this.applySession(null);
    this.router.navigate(['/login']);
  }
}
