import { NgModule } from '@angular/core';
import { CheckboxModule } from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {AccordionModule} from 'primeng/accordion';
import {CalendarModule} from 'primeng/calendar';

@NgModule({
  declarations: [],
  imports: [
    CheckboxModule
  ],
  exports: [
    CheckboxModule,
    DropdownModule,
    AccordionModule,
    CalendarModule
  ]
})
export class UiModule { }
