import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { SgCustomersService } from '../customers/customers.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanySelectedGuardGuard implements CanActivate {

  constructor(private companies: SgCustomersService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot) {
    const companyID = next.paramMap.get('companyID');

    if(companyID) {
      if(this.companies.currentCustomer?.id == +companyID) {
        return true;
      }

      return this.companies.activateCompany(+companyID)
        .pipe(tap(res => {
          if(!res) {
            this.router.navigateByUrl('/companies');
          }
        }));
    }

    return false;
  }
  
}
