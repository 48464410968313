<div class="item" [routerLink]="'/companies/' + companies.currentCustomer.id + '/dashboard'">
    <i class="icon-dashboard"></i>{{ 'menu.dashboard' | translate }}
</div>
<div class="item" [routerLink]="'/companies/' + companies.currentCustomer.id + '/pipelines'">
    <i class="icon-code-fork"></i>{{ 'menu.pipelines' | translate }}
</div>
<div class="item" [routerLink]="'/companies/' + companies.currentCustomer.id + '/variables'">
    <i class="icon-list-alt"></i>{{ 'menu.variables' | translate }}
</div>
<div class="item" [routerLink]="'/companies/' + companies.currentCustomer.id + '/settings'">
    <i class="icon-cogs"></i>{{ 'menu.settings' | translate }}
</div>
<div class="item" [routerLink]="'/companies/' + companies.currentCustomer.id + '/bills/invoices'">
    <i class="icon-euro"></i>{{ 'menu.bills' | translate }}
</div>
<div class="item" [routerLink]="'/companies/' + companies.currentCustomer.id + '/users'">
    <i class="icon-users"></i>{{ 'menu.users' | translate }}
</div>
<div class="item" [routerLink]="'/companies/' + companies.currentCustomer.id + '/troubleshoot/runs'">
    <i class="icon-power"></i>{{ 'menu.troubleshoot' | translate }}
</div>
<div class="item" [routerLink]="'/companies/' + companies.currentCustomer.id + '/docs'">
    <i class="icon-book1"></i>{{ 'menu.docs' | translate }}
</div>
