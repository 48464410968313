import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';

@Pipe({
  name: 'toFormArray'
})
export class ToFormArrayPipe implements PipeTransform {

  transform(control: AbstractControl): UntypedFormArray {
    return control as UntypedFormArray;
  }

}
