<div class="auth-container">
    <div class="auth-content">
        <app-login-form (onSuccess)="onSuccess()"></app-login-form>
        <div class="links">
            <span class="sg-link primary" [routerLink]="['/password-request']">Forgot password</span>
            <!-- |
            <span class="sg-link primary" [routerLink]="['/registration']">Create account</span> -->
        </div>
    </div>
</div>
