import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public breadcrumb: string;
  public triggerNavBack = new Subject<NavigationExtras>();
  public params: Record<string, string> = {};

  constructor(route: ActivatedRoute, router: Router) {

    router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
      )
      .subscribe(() => {
        let snapshot = route.snapshot;
    
        while (snapshot.firstChild) {
          snapshot = snapshot.firstChild;
        }
    
        this.params = snapshot.params;
      });

  }

  public getURL(template: string): string {
    const splitted = template.split('/');
    splitted.forEach((v, i) => {
      if (v?.startsWith(':')) {
        splitted[i] = this.params[v.split(':')[1]];
      }
    });
    return splitted.join('/');
  }

  public getCompanyApiURL(template: string): string {
    return environment.uri + this.getURL('customer/:companyID/' + template);
  }
}
