import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ClientOptsComponent } from '../client-opts/client-opts.component';

@Component({
  selector: 'sg-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss']
})
export class LangComponent implements OnInit {

  public lang: string;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(ev => {
      this.lang = ev.lang;
    });
  }

  public showOptions(): void {
    this.dialog.open(ClientOptsComponent, {width: '300px'})
      .afterClosed()
      .pipe(take(1))
      .subscribe(lang => {
        if (lang) {
          this.translate.use(lang);
          try {
            localStorage.setItem('lang', lang);
          } catch (e) {
            console.warn(e);
          }
        }
      });
  }
}
