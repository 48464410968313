import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';
import { SgCustomersService } from 'src/app/services/customers/customers.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  constructor(public session: SessionService, public companies: SgCustomersService) {
  }

  ngOnInit(): void {
  }

}
