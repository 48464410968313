import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '../../session/session.service';
import { SgCustomersService } from '../../customers/customers.service';
import { StateService } from '../../state/state.service';

@Injectable({
  providedIn: 'root'
})
export class SgBaseServicesService {
  constructor(public http: HttpClient, public state: StateService) { }
}
