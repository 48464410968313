import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { SgPipelinesService } from 'src/app/pages/pipelines/pipelines.service';
import { take } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SgiPipelineDetail } from 'src/app/pages/pipelines/pipelines.model';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
})
export class UploadFileDialogComponent {
  public type: string;
  public loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<UploadFileDialogComponent>,
    private pipelines: SgPipelinesService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: { pipeline: SgiPipelineDetail }
  ) {
    const mapped = {
      'file/txt': 'TXT',
      'file/xml': 'XML',
      'file/csv': 'CSV',
      'file/json': 'JSON',
      'file/xlsx': 'XLSX',
    }[data.pipeline.type];

    if (mapped) {
      this.type = mapped;
    }
  }

  handleFileInput(files: FileList) {
    const file = files.item(0);
    this.loading = true;
    var reader = new FileReader();
    reader.onload = () => {
      this.pipelines
        .runPipelineWithFile(
          reader.result as string,
          this.data.pipeline.id,
          file.type
        )
        .pipe(take(1))
        .subscribe((result) => {
          this.snackbar.open(
            result ? 'Pipeline started successfully.' : 'Some error occured.',
            'x',
            {
              duration: 2000,
            }
          );
          this.loading = false;
          this.onSuccess();
        });
    };
    reader.readAsText(file);
  }

  onSuccess(): void {
    this.dialogRef.close(true);
  }
}
