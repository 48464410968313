import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListLayoutComponent } from './list-layout.component';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { TableModule } from '../table/table.module';
import { PaginatorModule } from '../paginator/paginator.module';



@NgModule({
  declarations: [ListLayoutComponent],
  imports: [
    CommonModule,
    MatProgressBarModule,
    TableModule,
    PaginatorModule
  ],
  exports: [ListLayoutComponent]
})
export class ListLayoutModule { }
