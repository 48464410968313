import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToFormArrayPipe } from './to-form-array.pipe';



@NgModule({
  declarations: [ToFormArrayPipe],
  imports: [
    CommonModule
  ],
  exports: [
    ToFormArrayPipe
  ]
})
export class ToFormArrayModule { }
