import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state/state.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SgCustomersService } from 'src/app/services/customers/customers.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-selected-layout',
  templateUrl: './company-selected-layout.component.html',
  styleUrls: ['./company-selected-layout.component.scss']
})
export class CompanySelectedLayoutComponent implements OnInit {

  public backUrl: string;
  public lang: string = 'en';

  constructor(
    public state: StateService,
    public session: SessionService,
    public companies: SgCustomersService,
    public router: Router,
    public route: ActivatedRoute,
    public translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    merge(
      of(true),
      this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    )
      .subscribe(() => {
        const res = this.createBreadcrumbs(this.route.root);
        if(res.length > 1 && !res[res.length - 1].includes('dashboard')) {
          if (['bills', 'troubleshoot'].some(e => res[res.length - 2]?.endsWith(e))) {
            this.backUrl = res[res.length - 3];
          } else {
            this.backUrl = res[res.length - 2];
          }
        } else {
          this.backUrl = '';
        }
      });

    this.state.triggerNavBack
      .subscribe(extras => {
        this.router.navigate([this.backUrl], extras);
      });
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: string[] = []): string[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      if((!breadcrumbs.length || breadcrumbs[breadcrumbs.length - 1] !== url) && !child.snapshot.routeConfig.data?.skipBreadcrumb) {
        breadcrumbs.push(url);
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

}
