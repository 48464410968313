<ng-container *ngIf="customerInfo">
  <div class="plan-info active" *ngIf="customerInfo.is_subscribed; else notSubscribed" [ngClass]="{'on-dashboard': onDashboard}">
      <i class="icon-check"></i>
      <span class="text">{{ 'invoices.subscribedAccount' | translate }}</span>
  </div>
  
  <ng-template #notSubscribed>
    <div class="plan-info inactive" *ngIf="customerInfo">
      <i class="icon-warning"></i>
      <span class="text">{{ 'invoices.notSubscribedAccount' | translate }}</span>
      <div class="sg-btn sg-secondary" (click)="service.navigateToPlans()">
        {{ 'bills.changePlan' | translate }}
      </div>
    </div>
  </ng-template>
</ng-container>
