import { Component, Input } from '@angular/core';
import { BillsService, CompanyInfo } from 'src/app/pages/bills/bills.service';

@Component({
  selector: 'sg-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent {

  @Input() onDashboard?: boolean;

  public customerInfo?: CompanyInfo;

  constructor(public service: BillsService) {
  }

  ngOnInit() {
    this.service.getCustomerInfo()
      .subscribe(data => {
        this.customerInfo = data;
      });
  }
}
