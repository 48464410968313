import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SgDataSource } from './table.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnInit {

  public locale: string;

  @Input() dataSource: SgDataSource<T>;

  constructor(
    translate: TranslateService
  ) {
    this.locale = translate.currentLang;
    translate.onLangChange
      .subscribe(ev => this.locale = ev.lang);
  }

  ngOnInit(): void {
  }

}
