import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PseudoLogoComponent } from './pseudo-logo/pseudo-logo.component';
import { TabsModule } from './tabs/tabs.module';
import { DetailLayoutComponent } from './layouts/detail-layout/detail-layout.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { UploadFileDialogComponent } from './upload-file-dialog/upload-file-dialog.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from './button/button.module';
import { ListLayoutModule } from './list-layout/list-layout.module';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import { UiModule } from '../shared/ui/ui.module';
import { JsonInputModule } from './json-input/json-input.module';
import { ToFormArrayModule } from '../pipes/cast/to-form-array/to-form-array.module';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentInfoComponent } from './payment-info/payment-info.component';


@NgModule({
  declarations: [PseudoLogoComponent, DetailLayoutComponent, UploadFileDialogComponent, PaymentInfoComponent],
  imports: [
    CommonModule,
    TabsModule,
    MatDialogModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressBarModule,
    UiModule
  ],
  exports: [
    PseudoLogoComponent,
    TabsModule,
    DetailLayoutComponent,
    ListLayoutModule,
    ReactiveFormsModule,
    ButtonModule,
    MatCheckboxModule,
    JsonInputModule,
    TranslateModule,
    UiModule,
    ToFormArrayModule,
    MatProgressBarModule,
    PaymentInfoComponent
  ]
})
export class BaseComponentsModule { }
