import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientOptsComponent } from './client-opts.component';
import { LangModule } from '../lang/lang.module';



@NgModule({
  declarations: [ClientOptsComponent],
  imports: [
    CommonModule,
    LangModule
  ],
  exports: [
    ClientOptsComponent,
    LangModule
  ]
})
export class ClientOptsModule { }
