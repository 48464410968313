import { Injectable } from '@angular/core';
import { SgRemote } from 'src/app/services/core/remotes/remotes';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { SgiPipeline, SgiPipelineDetail, SgiPipelineRun } from './pipelines.model';
import { SgBaseServicesService } from 'src/app/services/core/remotes/base-services.service';
import { SgCustomersService } from 'src/app/services/customers/customers.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

interface PipelineRule {
  id: number;
  name: string;
  rulename: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class SgPipelinesService extends SgRemote {
  public pipelineFetch?: {
    id: number | string;
    observable: Observable<SgiPipelineDetail>;
  };
  private roles: ReplaySubject<PipelineRule[]>;
  private actionTypes: ReplaySubject<
    { name: string; hint: string; documentationId: number }[]
  >;

  constructor(
    services: SgBaseServicesService,
    private companies: SgCustomersService,
    private route: ActivatedRoute
  ) {
    super(services);
  }

  public getPipelines(
    page: number = 1,
    search: string = ''
  ): Observable<SgiPipeline[]> {
    return this.http
      .get<SgiPipeline[]>(
        this.getCompanyUri('pipelines', this.companies.currentCustomer.id),
        { params: { page: '' + page, search } }
      )
      .pipe(take(1));
  }

  public getPipeline(id: number | string): Observable<SgiPipelineDetail> {
    if (this.pipelineFetch?.id === id) {
      return this.pipelineFetch.observable;
    }

    const pipelineFetch = {
      id,
      observable: new Subject<SgiPipelineDetail>(),
    };

    this.pipelineFetch = pipelineFetch;

    this.http
      .get<SgiPipelineDetail>(
        this.getCompanyUri('pipelines/' + id, this.companies.currentCustomer.id)
      )
      .subscribe((data) => {
        pipelineFetch.observable.next(data);
        if (this.pipelineFetch === pipelineFetch) {
          this.pipelineFetch = undefined;
        }
      });

    return pipelineFetch.observable;
  }

  public updatePipeline(
    id: number | string,
    body: unknown
  ): Observable<SgiPipelineDetail> {
    const result: ReplaySubject<SgiPipelineDetail> = new ReplaySubject(1);
    this.http
      .put<SgiPipelineDetail>(
        this.getCompanyUri(
          'pipelines/' + id,
          this.companies.currentCustomer.id
        ),
        body
      )
      .pipe(this.copy(result))
      .subscribe();

    return result;
  }

  public loadActionTemplates(type: string): Observable<unknown> {
    return this.http.get(
      this.getURI('catalogs/action-types/template?action=' + type)
    );
  }

  public deletePipeline(id: string | number): Observable<boolean> {
    const result = new Subject<boolean>();
    this.http
      .delete(
        this.getCompanyUri('pipelines/' + id, this.companies.currentCustomer.id)
      )
      .pipe(this.copyToBool(result))
      .subscribe();
    return result;
  }

  public insert(body: unknown): Observable<SgiPipelineDetail> {
    const result: ReplaySubject<SgiPipelineDetail> = new ReplaySubject(1);
    this.http
      .post<SgiPipelineDetail>(
        this.getCompanyUri('pipelines/', this.companies.currentCustomer.id),
        body
      )
      .pipe(this.copy(result))
      .subscribe();

    return result;
  }

  public getPipelineRuns(
    id: number | string,
    page: number
  ): Observable<SgiPipelineRun[]> {
    return this.http
      .get<SgiPipelineRun[]>(
        this.getCompanyUri(
          'pipelines/' + id + '/runs',
          this.companies.currentCustomer.id
        ),
        { params: { page: '' + page } }
      )
      .pipe(take(1));
  }

  public runPipeline(pipelineID: number | string): Observable<boolean> {
    const result: ReplaySubject<boolean> = new ReplaySubject(1);
    this.http
      .post<unknown>(
        this.getCompanyUri(
          'pipelines/' + pipelineID + '/runs',
          this.companies.currentCustomer.id
        ),
        {},
        {
          params: {
            startRun: 'true',
          },
        }
      )
      .pipe(this.copyToBool(result))
      .subscribe();
    return result;
  }

  public runPipelineWithFile(
    data: string,
    pipelineID: number | string,
    type: string
  ): Observable<boolean> {
    const result: ReplaySubject<boolean> = new ReplaySubject(1);
    this.http
      .post<unknown>(
        this.getCompanyUri(
          'pipelines/' + pipelineID + '/runs',
          this.companies.currentCustomer.id
        ),
        {
          data: data,
        },
        {
          params: {
            startRun: 'true',
            fileType: type,
          },
        }
      )
      .pipe(this.copyToBool(result))
      .subscribe();
    return result;
  }

  public getRules(): Observable<PipelineRule[]> {
    if (!this.roles) {
      this.roles = new ReplaySubject();
      this.http
        .get<PipelineRule[]>(this.getURI('catalogs/rules'))
        .pipe(this.copy(this.roles))
        .subscribe();
    }
    return this.roles;
  }

  public getActionTypes(): Observable<
    {
      name: string;
      hint: string;
      documentationId: number;
    }[]
  > {
    if (!this.actionTypes) {
      this.actionTypes = new ReplaySubject();
      this.http
        .get<{ name: string; hint: string; documentationId: number }[]>(
          this.getURI('catalogs/action-types')
        )
        .pipe(this.copy(this.actionTypes))
        .subscribe();
    }
    return this.actionTypes;
  }

  public getDataPairs(
    page: number,
    search: string = ''
  ): Observable<{ id: number }[]> {
    return this.http.get<{ id: number }[]>(
      this.getCompanyUri(
        'pipelines/' + this.state.params.pipelineID + '/datapairs'
      ),
      { params: { page: '' + page, search } }
    );
  }

  public getDataPair(
    pipelineID: number,
    datapairID: number
  ): Observable<{ id: number }> {
    return this.http.get<{ id: number }>(
      this.getCompanyUri(
        'pipelines/' + pipelineID + '/datapairs/' + datapairID,
        this.companies.currentCustomer.id
      )
    );
  }

  public getSyncIds(
    page: number,
    search: string = ''
  ): Observable<{ id: number }[]> {
    return this.http.get<{ id: number }[]>(
      this.state.getCompanyApiURL('pipelines/:pipelineID/syncids'),
      { params: { page: '' + page, search } }
    );
  }

  public getSyncId(
    pipelineID: number,
    syncId: number
  ): Observable<{ value: string }> {
    return this.http.get<{ value: string }>(
      this.getCompanyUri(
        'pipelines/' + pipelineID + '/syncids/' + syncId,
        this.companies.currentCustomer.id
      )
    );
  }

  public deleteSyncId(pipelineID: number, syncId: number): Observable<unknown> {
    return this.http.delete(
      this.getCompanyUri(
        'pipelines/' + pipelineID + '/syncids/' + syncId,
        this.companies.currentCustomer.id
      )
    );
  }
}
